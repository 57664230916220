import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action, Getter } from 'vuex-class';
import GridTable from '@/components/ui/grid-table/gridTable.vue';
import { GridTableApi } from '@/components/ui/grid-table/gridTable';
import { MODAL_CONFIG } from '@/helpers/app.helper';
import { ActionsCellRendererParams } from '@/components/ui/grid-table/cell/actions-cell-renderer/actionsCellRenderer';
import ActionsCellRenderer from '@/components/ui/grid-table/cell/actions-cell-renderer/actionsCellRenderer.vue';
import { CompanyListRequest } from '@/types/request/company-request.types';
import { CompanyType } from '@/types/api/companies/company.type';
import { getShowingDate } from '@/helpers/date.helper';
import UpdateCompanyModal from '@/components/features/modals/update-company-modal/update-company-modal.vue';
import { UserChangeCompanyRequest } from '@/types/request/auth-request.types';
import { UserType } from '@/types/api/user/user.type';

const title = 'companies.title';

@Component({
  name: 'Companies',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    GridTable,
    ActionsCellRenderer,
  },
})
export default class Companies extends Vue {
  title = title;
  table: GridTableApi | null = null;

  @Action('getCompanies', { namespace: 'companies' })
  getCompanies!: (params?: CompanyListRequest) => Promise<CompanyType[]>;

  @Getter('getCompanies', { namespace: 'companies' })
  companies!: CompanyType[];

  @Action('companyChange', { namespace: 'users' })
  companyChange!: (params: UserChangeCompanyRequest) => Promise<void>;

  @Getter('getCurrentUser', { namespace: 'auth' })
  currentUser!: UserType;

  @Action('logOut', { namespace: 'auth' })
  logOut!: () => void;

  created() {
    this.getCompanies();
  }

  get tableFields() {
    return [
      {
        label: this.$t('companies.name'),
        key: 'name',
      },
      {
        label: this.$t('companies.createdAt'),
        key: 'createdAt',
        formatter: (value: string) => getShowingDate(value)
      },
      {
        label: this.$t('companies.userCount'),
        key: 'userCount',
      },
      {
        label: this.$t('companies.brandCount'),
        key: 'brandCount',
      },
      {
        label: '',
        key: 'actions',
        thStyle: { width: '40px' },
      },
    ];
  }

  getActionsParams(data): ActionsCellRendererParams<CompanyType> {
    return {
      data: data.item,
      buttons: [
        {
          type: 'enter',
          actionCallback: this.onEnterAction,
          btnContent: '<i class="fe-log-in" />',
        },
        {
          type: 'edit',
          actionCallback: this.onEditAction,
          btnContent: '<i class="fe-edit" />',
        },
      ]
    };
  }

  onEnterAction(params: ActionsCellRendererParams<CompanyType>) {
    this.$modal.show('dialog', {
      title: this.$t('companies.signInQuestion', { name: params.data.name}),
      text: this.$t('companies.signInText'),
      buttons: [
        {
          title: this.$t('common.cancel'),
          handler: () => {
            this.$modal.hide('dialog');
          }
        },
        {
          title: 'OK',
          handler: () => {
            this.companyChange({
              companyId: params.data.id,
              userId: this.currentUser.id
            })
              .then(() => {
                this.$modal.hide('dialog');
                this.$router.push({ name: 'logout' }).catch(() => {});
              })
          }
        }
      ]
    });
  }

  onEditAction(params: ActionsCellRendererParams<CompanyType>) {
    this.$modal.show(UpdateCompanyModal, {
      company: params.data,
      updateCallback: () => {
        this.getCompanies();
      }
    }, MODAL_CONFIG);
  }

  onAddNew() {
    this.$modal.show(UpdateCompanyModal, {
      updateCallback: () => {
        this.getCompanies();
      }
    }, MODAL_CONFIG);
  }
}
