import { Component, Prop, Vue } from 'vue-property-decorator';
import isFunction from 'lodash/isFunction';

interface IBtnOptions {
  disabled?: ((params: ActionsCellRendererParams<any>) => boolean) | boolean;
  show?: ((params: ActionsCellRendererParams<any>) => boolean) | boolean;
  actionCallback: ((params: ActionsCellRendererParams<any>) => any) | null;
  btnContent: string;
  type: string;
}

export interface ActionsCellRendererParams<T> {
  buttons: IBtnOptions[];
  data: T;
}

@Component
export default class ActionsCellRenderer extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  params!: ActionsCellRendererParams<any>;

  onClick(btn: IBtnOptions) {
    if (isFunction(btn.actionCallback)) {
      btn.actionCallback(this.params);
    }
  }

  btnContent(btn: IBtnOptions) {
    return btn.btnContent || '<i class="fe-edit"></i>';
  }

  isDisabled(btn: IBtnOptions): boolean {
    if (isFunction(btn.disabled)) {
      return btn.disabled(this.params);
    }
    return !!btn.disabled;
  }

  isShow(btn: IBtnOptions): boolean {
    if (isFunction(btn.show)) {
      return btn.show(this.params);
    }
    return btn.show === undefined ? true : !!btn.show;
  }
}
