import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import BTextareaInputValidation from '@/components/ui/inputs/b-textarea-input-validation/bTextareaInputValidation.vue';
import { showToast } from '@/helpers/toast.helper';
import { CompanyType } from '@/types/api/companies/company.type';
import { CompanyCreateRequest, CompanyUpdateRequest } from '@/types/request/company-request.types';
import { CompanyCard } from '@/store/modules/companies/actions';

@Component({
  name: 'UpdateCompanyModal',
  components: {
    ValidationObserver,
    BTextInputValidation,
    BTextareaInputValidation,
  },
})
export default class UpdateCompanyModal extends Vue {
  tryingToUpdate = false;
  name = '';
  description = '';

  @Prop({
    required: true
  })
  updateCallback!: (response: any) => any;

  @Prop({
    required: false
  })
  company?: CompanyType;

  @Action('companyCreate', { namespace: 'companies' })
  companyCreate!: (params: CompanyCreateRequest) => Promise<void>;

  @Action('companyUpdate', { namespace: 'companies' })
  companyUpdate!: (data: CompanyCard<CompanyUpdateRequest>) => Promise<void>;

  created() {
    if (this.company) {
      this.name = this.company.name;
      this.description = this.company.description;
    }
  }

  cancel() {
    this.$emit('close');
  }

  onApply() {
    if (!this.tryingToUpdate) {
      this.tryingToUpdate = true;
      const params = {
        ...(this.name ? { name: this.name } : {}),
        ...(this.description ? { description: this.description } : {}),
      };
      const action = this.company
        ? this.companyUpdate({ id: this.company.id, params })
        : this.companyCreate(params as CompanyCreateRequest)

      action
        .then(response => {
          this.tryingToUpdate = false;
          this.updateCallback(response);
          this.cancel();
          showToast(this.$t('common.successUpdate') as any);
        })
        .catch(() => {
          this.tryingToUpdate = false;
          showToast(this.$t('common.errorUpdate') as any);
        });
    }
  }
}
